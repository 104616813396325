import Axios from 'axios'

const buildFeedbackPayload = record => _.pick(record, [
  'comment',
  'options',
])

const assistantApi = {
  answers: {
    markCorrect(answerId) {
      return Axios.patch(`/api/ai/assistant/answers/${answerId}/mark_correct`)
    },

    markIncorrect(answerId) {
      return Axios.patch(`/api/ai/assistant/answers/${answerId}/mark_incorrect`)
    },

    removeFeedback(answerId) {
      return Axios.delete(`/api/ai/assistant/answers/${answerId}/remove_feedback`)
    },
  },

  answerFeedbacks: {
    create(answerId, record) {
      return Axios.post(`/api/ai/assistant/answers/${answerId}/answer_feedbacks`, { answerFeedback: buildFeedbackPayload(record) })
    },
  },

  knowledgeIngestions: {
    fetchAll() {
      return Axios.get('/api/ai/assistant/knowledge_ingestions')
    },

    create(record, source = 'wizard') {
      return Axios.post(
        '/api/ai/assistant/knowledge_ingestions',
        { knowledgeIngestion: record, source }
      )
    },
  },

  activeChannels: {
    fetchAll() {
      return Axios.get('/api/ai/assistant/active_channels')
    },
  },

  dashboardData: {
    fetch() {
      return Axios.get('/api/ai/assistant/dashboard_data')
    },
  },
}

export default assistantApi
